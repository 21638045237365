.whatsNewTitle{
    color:#183D6E;
    font-size: 18px;
    font-weight: bold;
    align-self: center;
    justify-content: center;
    padding-left: 40px;
}

.hr-color{
    border-color: #F5F6FA;
}

.mt-20{
    margin-top: 20px;
}

.title-div{
    /* display: grid;
    grid-template-columns: 17% 15% 15% 15% 14% 20%;
    grid-template-areas: "title search search emapty empty button";
    grid-template-rows: 75px; */
    grid-template-columns: auto 200px;
    display: grid;
    height: 75px;
}


.search-div{
   
    align-self: center;
    padding-left: 0px !important;
    /* position: relative;
    right: 15px; */
}
.btn-div{
    align-self: center;
    padding-right: 25px !important;

}

@media(max-width:994px) {
    .btn-div{
        justify-self: center;
    
    }
    
  }