.edit-box{
    background-color: #F6F6F6;
    border: 1px #F6F6F6 solid;
    border-radius: 3px 3px 3px 3px;
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
}
.grid-display{
    display: grid !important;
    justify-content: center;
}
.btn_search{
    background-color: #183D6E;
    border: solid 1px  #183D6E;
    color: #FFFFFF;
    border-radius: 3px;
    cursor: pointer;
    width:100%
}
.centerdiv{
    text-align: center;
    justify-content: center;
}
.edit-a{
    float: right;
}
