body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F6FA !important;
  height: 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clickable {
  cursor: pointer;
}

.mainstyle{
  background-color: white;
  margin-top: 21px;
  padding: 0px !important;
  border: solid 1px white;
  border-radius: 3px 3px 3px 3px;
  /* height: 787px; */
}

.bold{
  font-weight: bold;
}


#style-2{
   
  height: 399px !important; 
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

#details-scroll{
  height: 570px !important; 
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

@media(min-height: 800px) and (max-height: 950px) {
  #style-2{
    height: 500px !important; 
  }
  #details-scroll{
    height: 700px !important; 
  }
  
}

@media(min-height:950px) {
  #style-2{
    height: 700px !important; 
  }
  #details-scroll{
    height: 800px !important; 
  }
  
}


#style-2::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #dcebff;
}

#style-2::-webkit-scrollbar{
  width: 3px;
  background-color: #f5f5f5;

}

#style-2::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: #19a5a2;
} 


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(24, 61, 110,0.5);
  font-weight: 700;
  font-size: 14px;
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(24, 61, 110,0.5);
  font-weight: 700;
  font-size: 14px;
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(24, 61, 110,0.5);
  font-weight: 700;
  font-size: 14px;
}
:-moz-placeholder { /* Firefox 18- */
  color: rgba(24, 61, 110,0.5);
  font-weight: 700;
  font-size: 14px;
}


#serach_input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #D4D4D4;
  font-weight: 500;
  font-size: 14px;
}
#serach_input::-moz-placeholder { /* Firefox 19+ */
  color: #D4D4D4;
  font-weight: 500;
  font-size: 14px;
}
#serach_input:-ms-input-placeholder { /* IE 10+ */
  color: #D4D4D4;
  font-weight: 500;
  font-size: 14px;
}
#serach_input:-moz-placeholder { /* Firefox 18- */
  color: #D4D4D4;
  font-weight: 500;
  font-size: 14px;
}