/* .nav_div{
  
    padding: 0px !important;
    margin-top: 21px;
  } */
  
  .nav_div_link{
    padding-left: 5% !important;
    padding-right: 5% !important;
    height: 100%;
    justify-self: center;
    
  }

  .nav-element{
    margin-left: 30px;
    margin-right: 30px;
    background-color: white;
    padding-left: 0px !important;
    padding-right: 0px !important;
    display: grid;
    grid-template-rows: 2fr 1fr;
    border-radius: 3px 3px 3px 3px;
  }
  
  .nav_first_item{
    background-color: white;
    border: solid 1px white;
    border-radius: 3px 3px 3px 3px;
    padding: 0.7rem !important;
  }
  .logo-img{
    width: 100%;
  }
  .nav_other_item{
    padding-top: 1.5rem !important;
    display: flex !important;
  }
  
  .img_item{
    padding-right: 18px;
  }
  .img_item_oppacity{
    opacity: 0.5;
    padding-right: 18px;
  }
  .nav-link{
  
    color: #183D6E !important;
    font-weight: 700;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .nav-link-oppacity{
  
    color: rgba(24, 61, 110,0.5) !important;
    font-weight: 700;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding: .5rem 1rem;
  }
  .nav-item:hover{
      color: #183D6E !important; 
      text-decoration: none;
  }
  
  @media(max-width:575px) {
    .nav_div_link{
      float: left;
    }
    
  }
  
  @media(max-width:630px) {
    .nav_div_link{
      margin-right: 4px;
    }
    
  }

  @media(max-width:991px) {
    .nav_div_link{
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    
  }

  
  @media(max-width:1068px)  {  
  .nav-element{
    padding-left: 4px !important;
  }
}