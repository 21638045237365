.logout-div{
    background-color: white;
    position: absolute;
    bottom: 0px;
    padding-top:15px;
    border: 1px white solid;
    border-radius: 3px 3px 3px 3px;
   
    width: 100%;
}

.top-div{
     padding-left: 25px;
  

}

.bottom-div{
    padding-left: 25px;    
    padding-bottom: 9px;
    cursor: pointer;
}

.line-hr{
    border: 1px solid #F5F6FA;
    margin-bottom: 10px;
    margin-top: 10px;
}

.email-div{
    font-size: 12px;
    color: #183D6E;
}

.logout-span{
    font-size:12px;
    color: #183D6E;
    padding-left: 5px;
}

.name-header{
    color: #183D6E;
}

