th{
    border: none !important;
    color: #183D6E !important;
    vertical-align: top !important;
    padding: 10px
}

td {
    color: #6E6E6E;
    font-weight: 500;
    vertical-align: top !important;
    border-top: 1px solid rgba(170, 176, 193, 0.5) !important;
}
tr{
    padding: 10px;
}
 .anchor-table{
    color: #6E6E6E !important;
    font-weight: 500 !important;
    text-decoration: none !important;
}

.anchor-table :hover{

    color: #6E6E6E !important;
    font-weight: 500 !important;
    text-decoration: none !important;
}

.title-box{
    background-color: #F6F6F6;
    border: 1px #F6F6F6 solid;
    border-radius: 7px 7px 7px 7px;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.div-margin{
    margin-top: 2px;
    float: left;
    margin-right: 5px;
}

.edit-box{
    background-color: #F6F6F6;
    border: 1px #F6F6F6 solid;
    border-radius: 3px 3px 3px 3px;
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
}
.title-flex{
 display: flex;
}


@media(max-width:1023px) {
    .table{
     margin: 0px !important;
    }
    
  }

.publishing-padding{
  
    display: grid;
    justify-items: center;
}


.white-color{
    color: white !important;
}

@media  (min-width: 270px) and (max-width:767px) {
    .white-color{
        padding-right: 0px !important;
        padding-left: 0px  !important;
    }
    .td-delete{
        padding-right: 0px !important;
    }
    .td-edit{
        padding-right: 0px  !important;
        padding-left: 0px  !important;
    }
    
  }