.search_div{
  
    border-radius: 3px;
    border: solid 1px  #D4D4D4;
    margin-left: 30px !important;
    width: 280px;
}

.searchicon{
    color: #D4D4D4;
    align-self: center;
    padding-right: 0px !important;
    padding-left: 0px !important;
    display: grid !important;
    justify-content: center;
}
.search-ul{
    padding-left: 23px;
    margin: 0px;
}
.suggestion-list{
    display: block;
    color: #D4D4D4;
    font-weight: 500;
    font-size: 14px;
    border-top: 1px solid #D4D4D4;
    padding-top: 10px;
    padding-bottom: 10px;
}

.searchWord{
    font-weight: 500;
    font-size: 14px;
    border: 0px;
    width: 90%;

}

input:focus{
    outline: 0px !important;
}

.search_btn_div{
    margin-top: 22px;
    margin-bottom: 30px;
    
}



