.login-padding{
    padding-left: 30% !important;
    padding-right: 30% !important;
    padding-top: 5% !important;
}
.center-btn{
    text-align: center;
    padding-top: 5%;
    display: grid;
    justify-items: center;
    grid-template-rows: 50px;
}
.login-img{
    height: 100%;
}
.sign-in{
    text-align: center;
    font-size: 18px;
    color: #616976;
}
.login-box{
    height: 380px;
    width:360px;
    justify-self: center;
    background-color: white;
    border: 1px solid white;
    border-radius: 3px 3px 3px 3px;
}
.login-container{
    display: grid;
}