.info-div{
    color: #183D6E;
    font-size: 16px;
    font-weight: bold;
}

.settings-label{
    display: block;
    font-size: 14px;
    color: #6E6E6E;
    font-weight: 500;
}


.settings-input{
    height: 36px;
    border-radius: 3px 3px 3px 3px;
    border: solid 1px #D4D4D4;
    background-color: #F0F1F7;
    font-size: 14px;
    color: #6E6E6E;
}

.btn-settings{
    border-radius: 3px 3px 3px 3px;
    border: #183D6E 1px solid;
    height: 40px;
    background-color: #183D6E;
    color: white;
    font-size: 14px;
    margin-bottom: 10px;
}
.show-label{
    font-size: 14px;
    color: rgba(110, 110, 110,0.5);
    position: relative;
    right: 63px;
}
.btn-cancel{
    border-radius: 3px 3px 3px 3px;
    border: #A51E36 1px solid;
    height: 40px;
    background-color: white;
    color: #A51E36;
    font-size: 14px;
    margin-bottom: 10px;
}