.label-form{
    color: #183D6E;
    font-size: 16px;
    font-weight: 600;
    
}

.input-title{
    display: block;
    width: 100%;
    height: 50px;
    background-color: #F0F1F7;
    border: 1px solid #D4D4D4;
    border-radius: 3px 3px 3px 3px;
    color: #183D6E;
    padding: 24px;
    font-weight: 500;
    font-size: 14px;

}

.source-li{
    background-color: #F5F6FA;
    border: 3px #F5F6FA solid;
    margin-right: 15px;
    margin-bottom: 15px;
    padding-left: 5px;
}

.source-name{
    color: #183D6E;
    font-weight: 600;
}
.source-name:hover{
    color:  #183D6E;
    text-decoration: none;

}
.removeButton{
    color: #183D6E;
    border: none;
    display: inline;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
}
.addfield-ul  {
    list-style-type: none;
}
.add-field-input{
    display: inline;
    margin-right: 20px;
}

.input-body{
    display: block;
    width: 100%;
    background-color: #F0F1F7;
    border: 1px solid #D4D4D4;
    border-radius: 3px 3px 3px 3px;
    color: #183D6E;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 18px;
    padding-bottom: 55px;
    font-weight: 500;
    font-size: 14px;
}

.input-btn{
    width: 17%;
    color: white;
    background: #183D6E;
    border: 1px solid #183D6E;
    border-radius: 3px 3px 3px 3px;
    padding: 1%;
    font-size: 14px;
    margin-bottom: 35px;
}
.plus-btn
{
    color: #183D6E;
    background:#F5F6FA;
    border: 1px solid #F5F6FA;
    border-radius: 3px 3px 3px 3px;
    width: 50px;
    height: 50px;
}
.img-input{
    width: 160px;
    height: 90px;
    background-color: #F5F6FA;
    border: 1px solid #F5F6FA;
    border-radius: 4px 4px 4px 4px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 160px 110px;
    grid-template-rows: 90px;
 
}
.size-div{
    font-size: 12px;
    color: #6E6E6E;
}
.recomendation_div{
    font-size: 12px;
    color: #183D6E;
    font-weight: 500;
}

.img-info{
    font-size: 12px;
    color: #6E6E6E;
    font-style: italic;
    font-weight: 400;
}

.inp-checkbox {
    -webkit-appearance: none;
    background-color: #dfdddd;
    border-radius: 8px 8px 8px 8px !important;
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 8px;

}  
.inp-checkbox:focus{
    outline: 0px;
}
.inp-checkbox:checked:after{
    font-family: "FontAwesome";
    content: "\f00c";
    position: relative;
    left: 3px;
    color: #A01010;
}
.output-img{
    width: 160px;
    height: 90px;
}
.input-file{
    text-align: right;
    z-index: 2;
    width: 160px;
    display: none;
}
  
@media(max-width:630px) {
    .input-btn{
      width: 20%;
    }
      .output-img{
          width: 100%;
      }
  }

